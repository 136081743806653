import React from "react";
import Header from "../../components/Header";
import Hero from "./Hero";
import Information from "./Information";
import Testimonials from "./Testimonials";
import Faq from "./Faq";
import Pricing from "../../components/home/Pricing";
import LogoSlider from "../../components/home/LogoSlider";

type Props = {};

const Home = (props: Props) => {
  return (
    <main className="h-full w-full font-montserrat">
      <section className=" overflow-hidden bg-[#1D2939]">
        <img
          src="/light.svg"
          alt=""
          className="absolute right-0 top-0 z-0 h-full w-full lg:-right-14"
        />
        <section className="relative z-20">
          <Hero />
        </section>
      </section>
      <section className="relative z-20 bg-white">
        <div className="pt-12">
          <Information />
        </div>
      </section>
      <section>
        <Testimonials />
      </section>
      <section>
        <Pricing />
        <LogoSlider />
      </section>
      <section>
        <Faq />
      </section>
    </main>
  );
};

export default Home;
