import React from "react";
import cap from "../../assets/home/cap.png";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

type Props = {};

const Faq = (props: Props) => {
  return (
    <main className="bg-[#DBE2FE] px-4 py-12 xl:px-20">
      <aside className="mx-auto max-w-[343px] text-center lg:max-w-[768px]">
        <img
          src={cap}
          alt=""
          className="mx-auto h-[203px] w-[203px] object-contain lg:mx-0 lg:ml-8"
        />

        <article className="mx-auto -mt-16">
          <h1 className="text-[32px] font-bold">Frequently asked questions</h1>
          <p className="text-base font-medium text-[#646462]">
            Everything you need to know about the product and billing.
          </p>
        </article>
      </aside>
      <aside className="mx-auto mt-16 w-full max-w-[656px]">
        <Accordion
          type="single"
          collapsible
          className="w-full space-y-8 text-left"
        >
          <AccordionItem value="item-1">
            <AccordionTrigger>What is Nurovant?</AccordionTrigger>
            <AccordionContent>
              Nurovant is an AI-powered learning companion designed to
              revolutionize digital education. It captures, analyzes, and
              facilitates understanding of lecture content, transforming dense
              information into manageable content for enhanced learning
              efficiency.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2" className="text-left">
            <AccordionTrigger>
              How does Nurovant help with the information overload that students
              experience?
            </AccordionTrigger>
            <AccordionContent>
              Nurovant mitigates the problem of information overload in digital
              education by recording lectures and refining them into manageable
              content. This process allows students to effectively process and
              retain information at their own pace.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>
              How does Nurovant improve comprehension and recollection of
              lecture content?
            </AccordionTrigger>
            <AccordionContent>
              Nurovant uses advanced AI to extract crucial insights from
              lectures, which can be reviewed and studied at a later time. It
              also facilitates post-lecture recaps and quizzes, helping students
              establish a deeper connection with the learning material and
              improving comprehension and recollection.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>
              How does Nurovant solve the problem of aligning class content with
              external resources?
            </AccordionTrigger>
            <AccordionContent>
              By recording and analyzing lectures, Nurovant provides students
              with a personalized learning resource directly linked to their
              course material. This reduces reliance on external resources,
              ensuring study effectiveness.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>
              What is the market size for AI in education?
            </AccordionTrigger>
            <AccordionContent>
              A report by Global Market Estimates projects that the global AI in
              education market will grow from USD 4.25 billion in 2023 to USD
              20.65 billion in 2028, at a CAGR of 45.9% from 2023 to 2028.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6">
            <AccordionTrigger>
              Is my data secure with Nurovant?
            </AccordionTrigger>
            <AccordionContent>
              Yes, Nurovant is committed to ensuring data security and privacy.
              We implement industry-leading encryption standards,
              privacy-by-design principles, and secure servers to protect your
              data
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-7">
            <AccordionTrigger>How does Nurovant work?</AccordionTrigger>
            <AccordionContent className="space-y-5">
              <p>
                {" "}
                1. Lecture Recording: Students initiate the process by recording
                their lectures using the Nurovant app on their mobile devices.
                The app can handle lecture durations ranging from five minutes
                to an hour and a half.
              </p>
              <p>
                {" "}
                2. Data Transmission & Processing: After the lecture is
                recorded, the real magic happens. Within 5-30 minutes, Nurovant
                employs NLP to process and analyze the lecture content,
                dissecting the material and identifying key concepts.
              </p>

              <p>
                3. Features Utilization: Once the analysis is complete, students
                can access several features. These include Nurovant Recaps for
                condensed lecture summaries, LectureMate to answer academic
                queries based on the lecture content, Recap Cards that serve as
                digital flashcards of key concepts, and QuizGenerator for
                self-assessment. Additionally, students can create personalized
                study plans with the Study Guide Plans feature.
              </p>
              <p>
                {" "}
                By incorporating AI and NLP, Nurovant creates a comprehensive,
                convenient, and effective learning experience tailored for
                digital education.
              </p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-8">
            <AccordionTrigger>
              What are the key features of Nurovant?
            </AccordionTrigger>
            <AccordionContent>
              Nurovant comes with several innovative features that enhance the
              learning experience, including the 'QuizGenerator' for
              self-assessment, 'LectureMate' for interactive learning, 'Recap
              Cards' for mastering key concepts, 'Nurovant Recaps' for quick
              content overviews, and 'Study Guide Plans' for personalized study
              schedules.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-9">
            <AccordionTrigger>
              How does 'LectureMate' enhance my learning?
            </AccordionTrigger>
            <AccordionContent>
              LectureMate' is a chatbot feature in Nurovant that uses AI
              technologies to understand and answer academic-related queries. It
              provides a guided study experience, allowing students to clarify
              doubts or questions that were not addressed in the lectures.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-10">
            <AccordionTrigger>
              What are 'Recap Cards', and how do they assist my study?
            </AccordionTrigger>
            <AccordionContent>
              Recap Cards' are AI-generated flashcards that are created based on
              the keywords analyzed from your lecture materials. They present a
              keyword on one side and its definition on the other, aiding in
              understanding and remembering key concepts and terms from your
              lectures.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-11">
            <AccordionTrigger>
              How does Nurovant's 'Study Plans' feature help me with my study
              schedule?
            </AccordionTrigger>
            <AccordionContent>
              The 'Study Guide Plans' feature in Nurovant allows users to create
              personalized study plans based on their course and schedule. The
              AI takes into account the user's timeframe and lecture keywords to
              build an effective study plan, either from an inserted transcript
              or simply from inputted keywords.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-12">
            <AccordionTrigger>
              What are 'Nurovant Recaps' and how does it help me understand my
              lecture content better?
            </AccordionTrigger>
            <AccordionContent>
              Nurovant Recaps' is a feature that creates concise summaries for
              each segment of lecture content. It gives students a quick
              overview of the material, enabling them to grasp the core points
              without having to revisit the entire lecture.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-13">
            <AccordionTrigger>
              How does Nurovant use Artificial Intelligence in its features?
            </AccordionTrigger>
            <AccordionContent>
              Nurovant leverages advanced AI technologies to transform dense
              lecture content into manageable units, extract key insights,
              generate quizzes and flashcards, and provide interactive learning
              experiences. This intelligent use of AI helps to enhance
              comprehension and recollection of the lecture content.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-14">
            <AccordionTrigger>
              Does Nurovant support all academic disciplines?
            </AccordionTrigger>
            <AccordionContent>
              Nurovant is designed with a broad academic scope in mind, making
              it an effective learning tool for a wide range of disciplines.
              Whether you are studying humanities, natural sciences, social
              sciences, or professional courses, Nurovant can be a valuable
              asset in your academic journey.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </aside>
    </main>
  );
};

export default Faq;
