import React, { useState } from "react";
import { Button } from "./ui/button";
import logo from "../assets/home/logo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";
import { Menu } from "lucide-react";
import { cn } from "../lib/utils";
import { PopupButton } from "react-calendly";

type Props = {};

const Header = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isStudentsPage = location.pathname === "/students";
  const isTeachersPage = location.pathname === "/teachers";
  const isInstitutionsPage = location.pathname === "/institutions";
  const isAboutUsPage = location.pathname === "/about-us";

  const links = [
    {
      name: "Students",
      link: "/students",
    },
    {
      name: "Teachers",
      link: "/teachers",
    },
    {
      name: "Institutions",
      link: "/institutions",
    },
    {
      name: "About us",
      link: "/about-us",
    },
  ];

  const getLink = () => {
    if (isTeachersPage) {
      return "https://school.nurovant.com";
    } else if (isInstitutionsPage) {
      return "mailto:support@nurovant.com";
    } else return "https://app.nurovant.com/";
  };

  const getText = () => {
    if (isInstitutionsPage) {
      return "Contact Us";
    } else {
      return "Get Started";
    }
  };
  return (
    <main
      className={cn(
        "flex justify-between bg-[#1D2939] px-4 py-6 lg:px-20",
        isStudentsPage && "bg-[#E6F1FE] lg:bg-transparent",
        isTeachersPage && "bg-white ",
        isInstitutionsPage && "bg-[#F9F1E5] lg:bg-transparent",
        isAboutUsPage && "bg-white",
      )}
    >
      <nav className="relative z-20 flex items-center lg:gap-[134px]">
        <Link to={"/"} className="ml-4 lg:ml-0">
          <img
            src={logo}
            alt="nurovant-ai"
            className="h-[21px] max-w-[133px] object-contain lg:h-[26px] lg:max-w-[165px]"
          />
        </Link>
      </nav>
      <nav className="relative z-20 hidden items-center gap-4 text-[18px] font-medium lg:flex">
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={link.link}
            className={({ isActive }) =>
              isActive ? "text-primary" : "text-[#838382]"
            }
          >
            {link.name}
          </NavLink>
        ))}
      </nav>

      <div className="relative z-20 lg:hidden">
        <Sheet onOpenChange={setIsOpen} open={isOpen}>
          <SheetTrigger>
            <Menu
              className={cn(
                "text-white",
                isStudentsPage && "text-black",
                isTeachersPage && "text-black",
                isInstitutionsPage && "text-black",
                isAboutUsPage && "text-black",
              )}
            />
          </SheetTrigger>
          <SheetContent side={"right"} className="p-0">
            <article className="flex flex-col gap-4 pl-6 pt-16 text-[18px] font-medium">
              {links.map((link, index) => (
                <NavLink
                  onClick={() => setIsOpen(false)}
                  key={index}
                  to={link.link}
                  className={({ isActive }) =>
                    isActive
                      ? "border-[2px] border-transparent border-r-primary text-primary"
                      : "text-[#838382]"
                  }
                >
                  {link.name}
                </NavLink>
              ))}
            </article>
          </SheetContent>
        </Sheet>
      </div>
      <nav className="relative z-20 hidden lg:block">
        {isInstitutionsPage ? (
          <PopupButton
            url="https://calendly.com/support-0586"
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")!}
            text="Contact Us"
            styles={{
              color: "#ffffff",
              backgroundColor: "#4a71fc",
              borderRadius: "30px",
              padding: "12px 24px",
            }}
          />
        ) : (
          <Link target="__blank" to={getLink()}>
            <Button className="rounded-3xl font-GontserratLight text-base lg:px-[43px] lg:py-[25px] lg:text-[18px]">
              {getText()}
            </Button>
          </Link>
        )}
      </nav>
    </main>
  );
};

export default Header;
