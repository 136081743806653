import React, { useEffect } from "react";
import testimonials from "../../lib/testimonials";
import { cn } from "../../lib/utils";

type Props = {};

const Testimonials = (props: Props) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex]);
  return (
    <main className="px-4 pb-2 pt-[48px] lg:px-[114px] lg:pb-[150px] lg:pt-[123px]">
      <article className="mx-auto w-fit max-w-[1216px]">
        <p className="py-5 text-sm font-semibold text-primary">Testimonials</p>
        <h1 className="text-center text-[24px] font-bold lg:text-[48px]">
          Over 500,000 Students, Professionals, and Researchers have used
          Nurovant to stay ahead
        </h1>
      </article>
      <article className="relative my-3">
        <aside className="relative mx-auto flex  h-[500px] max-w-[400px] flex-col overflow-hidden rounded-3xl  bg-[#F9FAFB] md:max-w-[400px] lg:h-[290px]  lg:max-w-[700px] xl:flex-row">
          {testimonials.map((testimonial, index) => (
            <div
              style={{
                transform: `translateX(${(index - currentIndex) * 100}%)`,
                transition: "transform 0.5s",
                position: "absolute",
              }}
              className="flex flex-col items-center gap-2 xl:flex-row"
            >
              <article className="mt-8 space-y-2 px-3 lg:px-6">
                <p className="text-[24px] font-semibold">{testimonial.text}</p>
                <p className="text-[16px] font-medium text-black">
                  - {testimonial.name}
                </p>
              </article>
              {/* <img
                src={testimonial.image}
                alt=""
                className="h-[464px] w-[480px]"
              /> */}
            </div>
          ))}
          <aside className="absolute bottom-0 left-6 z-20 space-x-3">
            {Array.from({ length: testimonials.length }).map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={cn(
                  " h-2 w-2 rounded-full",
                  index === currentIndex
                    ? "bg-primary"
                    : "bg-[#E2E8F0] hover:bg-primary",
                )}
              />
            ))}
          </aside>
          {/* <div className="ml-auto mt-9 max-w-[420px]">
            <img
              src={testimonials[1].image}
              alt=""
              className="h-[42px] w-[42px]"
            />
            <article className="space-y-2">
              <p className="text-[16px] text-[#A2A2A1]">
                {testimonials[1].name}
              </p>
              <p>{testimonials[1].text}</p>
            </article>
          </div>
          <div>
            <img
              src={testimonials[2].image}
              alt=""
              className="h-[42px] w-[42px]"
            />
            <article className="space-y-2">
              <p className="text-[16px] text-[#A2A2A1]">
                {testimonials[2].name}
              </p>
              <p>{testimonials[2].text}</p>
            </article>
          </div> */}
        </aside>
        {/* <aside className="mx-auto flex flex-col gap-4 sm:max-w-[500px] xl:max-w-[1213px] xl:flex-row">
          <div className="ml-auto mt-9 max-w-[420px] xl:ml-0 xl:mt-0">
            <img
              src={testimonials[3].image}
              alt=""
              className="h-[42px] w-[42px]"
            />
            <article className="space-y-2">
              <p className="text-[16px] text-[#A2A2A1]">
                {testimonials[3].name}
              </p>
              <p>{testimonials[3].text}</p>
            </article>
          </div>
          <div className="xl:ml-auto xl:mt-9 xl:max-w-[420px]">
            <img
              src={testimonials[4].image}
              alt=""
              className="h-[42px] w-[42px]"
            />
            <article className="space-y-2">
              <p className="text-[16px] text-[#A2A2A1]">
                {testimonials[4].name}
              </p>
              <p>{testimonials[4].text}</p>
            </article>
          </div>
          <div className="ml-auto mt-9 max-w-[420px] xl:ml-0 xl:mt-0">
            <img
              src={testimonials[5].image}
              alt=""
              className="h-[42px] w-[42px]"
            />
            <article className="space-y-2">
              <p className="text-[16px] text-[#A2A2A1]">
                {testimonials[5].name}
              </p>
              <p>{testimonials[5].text}</p>
            </article>
          </div>
        </aside> */}
      </article>
    </main>
  );
};

export default Testimonials;
