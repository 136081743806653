import { Check } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const Pricing = (props: Props) => {
  return (
    <main className=" mt-10 px-4 lg:mt-[96px]">
      <article className="text-center">
        <p className="text-sm text-primary">Pricing</p>
        <h1 className="text-[32px] font-semibold">Plans that fit your scale</h1>
        <p>
          Simple, transparent pricing that grows with you. Try any plan free for
          30 days.
        </p>
      </article>
      <section className="my-16 flex flex-wrap justify-center gap-8">
        <aside className="relative w-full max-w-[384px] rounded-[18px] shadow-xl lg:shadow-2xl">
          <div className="absolute -right-8 -top-8">
            <p className="ml-10 text-sm text-primary">Most popular!</p>
            <img src="/hand-arrow.svg" alt="" className="h-[30px] w-[61px]" />
          </div>
          <div className="p-8">
            <article className="flex items-center justify-between">
              <p className="text-[18px] font-semibold text-[#838382]">
                Premium plan
              </p>
              <p className="rounded-full bg-[#F9F5FF] px-3 py-1 font-medium text-[#6941C6]">
                Popular
              </p>
            </article>
            <article className="flex items-end gap-2">
              <h1 className="text-[48px] font-bold lg:text-[56px]">
                $9.99
                <span className="text-sm font-normal text-[#646462]">
                  per month
                </span>
              </h1>
            </article>
            <p className="mt-4 text-[#667085]">
              Basic features for up to 10 users.
            </p>
            <Link target="__blank" to="https://app.nurovant.com">
              <button className="mt-8 w-full rounded-full bg-primary py-3 text-white">
                Start Free Trial
              </button>
            </Link>
          </div>
          <hr />
          <article className="p-3 lg:p-8">
            <h1 className="mb-3 font-medium">FEATURES</h1>
            <ul className="space-y-3 ">
              <li className="flex gap-2">
                {" "}
                <div className="rounded-full bg-primary p-1">
                  <Check className="h-4 w-4 text-white" />
                </div>
                <p>Get access to all features</p>
              </li>
              <li className="flex gap-2">
                {" "}
                <div className="rounded-full bg-primary p-1">
                  <Check className="h-4 w-4 text-white" />
                </div>
                <p>500 credits per month</p>
              </li>
            </ul>
          </article>
        </aside>
        <aside className="w-full max-w-[384px] rounded-[18px] shadow-xl lg:shadow-2xl">
          <div className="p-8">
            <article className="flex items-center justify-between">
              <p className="text-[18px] font-semibold text-[#838382]">
                Pro plan
              </p>
            </article>
            <article className="flex items-end gap-2">
              <h1 className="text-[48px] font-bold lg:text-[56px]">
                $24.99
                <span className="text-sm font-normal text-[#646462]">
                  per month
                </span>
              </h1>
            </article>
            <p className="mt-4 text-[#667085]">Unlimited features</p>
            <Link target="__blank" to="https://app.nurovant.com">
              <button className="mt-8 w-full rounded-full bg-primary py-3 text-white">
                Start Free Trial
              </button>
            </Link>
          </div>
          <hr />
          <article className="p-3 lg:p-8">
            <h1 className="mb-3 font-medium">FEATURES</h1>
            <ul className="space-y-3 ">
              <li className="flex gap-2">
                {" "}
                <div className="rounded-full bg-primary p-1">
                  <Check className="h-4 w-4 text-white" />
                </div>
                <p>Unlimited access to all features</p>
              </li>
              <li className="flex gap-2">
                {" "}
                <div className="rounded-full bg-primary p-1">
                  <Check className="h-4 w-4 text-white" />
                </div>
                <p>Unlimited credit</p>
              </li>
            </ul>
          </article>
        </aside>
      </section>
    </main>
  );
};

export default Pricing;
