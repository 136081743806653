import React from "react";
import Typewriter from "typewriter-effect";
import dropZone from "../../assets/home/dropzone-desktop.png";
import dropZoneMobile from "../../assets/home/dropzone-mobile.png";
import bigDropzone from "../../assets/home/big-dropzone.svg";
import testDropzone from "../../assets/home/testDropzone.png";
import nurovantBird from "../../assets/nurovant-bird.png";
import infoImage from "../../assets/home/hero-image.png";
import infoImageM from "../../assets/home/infoImageM.png";
import { Link } from "react-router-dom";
import googleplay from "../../assets/googleplay.svg";
import applestore from "../../assets/applestore.svg";

type Props = {};

const Hero = (props: Props) => {
  return (
    <main className="mx-auto flex w-fit flex-col gap-3 overflow-x-hidden px-4 pt-10 text-white lg:flex-row lg:items-end lg:justify-center lg:gap-2 lg:px-0 lg:pt-[110px] xl:gap-6">
      <article className=" font-GontserratBold  text-[48px] font-bold lg:pb-8 lg:text-[60px]">
        <p className="">
          <span>Generate tons of</span>
          <p className="">
            <Typewriter
              options={{
                strings: ["Flashcards", "Recaps", "Quizzes"],
                cursor: "",
                autoStart: true,
                loop: true,
                wrapperClassName: "text-primary",
              }}
            />
          </p>
        </p>
        <p className="">in seconds</p>
        <div className="relative z-20  mx-auto flex w-fit items-center gap-4 py-6 lg:mx-0">
          <Link
            target="_blank"
            to="https://play.google.com/store/apps/details?id=com.reflectly.ai&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              src={googleplay}
              alt=""
              className="h-[49px] w-[163px] object-contain"
            />
          </Link>

          <Link
            target="_blank"
            to="https://apps.apple.com/ng/app/nurovant-ai/id6461046210"
          >
            <img
              src={applestore}
              alt=""
              className="h-[49px] w-[163px] object-contain"
            />
          </Link>
        </div>
      </article>
      <aside className="">
        <figure className="mx-auto w-fit lg:mx-0 lg:h-[560px] lg:w-[576px]">
          <img
            src={infoImage}
            alt=""
            className="hidden h-full w-full object-contain pt-32 lg:block"
          />
          <img
            src={infoImageM}
            alt=""
            className="mx-auto max-h-[578px] max-w-[284px] object-contain lg:hidden"
          />
        </figure>
      </aside>
      {/* <figure className="relative mx-auto mt-8 flex w-fit items-end">
        <img
          src={testDropzone}
          alt="drop-zone"
          className="h-[381px] max-w-full object-contain lg:hidden"
        />
        <img
          src={bigDropzone}
          alt="drop-zone"
          className="hidden h-[381px] max-w-[816px] object-contain lg:block"
        />
        <img
          src={nurovantBird}
          alt=""
          className="absolute -right-10 max-h-[127px] max-w-[144px] object-contain lg:-right-[200px] lg:max-h-max lg:max-w-[335px] xl:-right-[250px]"
        />
      </figure> */}
    </main>
  );
};

export default Hero;
