import React from "react";
import logo from "../assets/home/logo.svg";
import googleplay from "../assets/googleplay.svg";
import applestore from "../assets/applestore.svg";
import { SocialIcon } from "react-social-icons";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { cn } from "../lib/utils";

type Props = {};

const Footer = (props: Props) => {
  const location = useLocation();

  const isAboutPage = location.pathname === "/about-us";
  return (
    <footer className={cn("px-4 py-16 lg:px-20", isAboutPage && "bg-gray-50")}>
      <section className="flex flex-col lg:flex-row lg:justify-between">
        <aside>
          <figure>
            <img
              src={logo}
              alt=""
              className="h-[32px] w-[207px] object-contain"
            />
          </figure>
          <article className="mt-6">
            <Link to="mailto:support@nurovant.com" className="">
              support@nurovant.com
            </Link>
            <div className="mt-8">
              <ul className="flex gap-6 text-base font-medium text-[#A2A2A1] sm:gap-8">
                <li>
                  <HashLink to={"/students#info"} smooth>
                    Features
                  </HashLink>
                </li>

                {/* <li>Privacy</li> */}
                {/* <li>Help</li> */}
              </ul>
            </div>
          </article>
        </aside>
        <aside className="mt-8 lg:mt-0">
          <p className="text-base font-medium text-primary">Get the app</p>
          <figure className="mt-6 flex flex-col gap-4">
            <Link
              target="_blank"
              to="https://play.google.com/store/apps/details?id=com.reflectly.ai&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img
                src={googleplay}
                alt=""
                className="h-[40px] w-[135px] object-contain"
              />
            </Link>
            <Link
              target="_blank"
              to="https://apps.apple.com/ng/app/nurovant-ai/id6461046210"
            >
              <img
                src={applestore}
                alt=""
                className="h-[40px] w-[135px] object-contain"
              />
            </Link>
          </figure>
        </aside>
      </section>
      <section className="mt-16 flex flex-col gap-4 text-[#98A2B3] lg:flex-row lg:justify-between">
        <aside>
          <p>
            &copy;{new Date().getFullYear()} Nurovant AI. All rights reserved.
          </p>
        </aside>
        <aside className="flex gap-4">
          <SocialIcon
            url="https://www.linkedin.com/company/nurovantai/"
            className="!h-6 !w-6"
            bgColor="#98A2B3"
          />
          <SocialIcon
            url="https://x.com/nurovant43999?t=JdVNnKGvs0_J2TXIzYF84Q&s=09"
            className="!h-6 !w-6"
            bgColor="#98A2B3"
          />
          <SocialIcon
            url="https://medium.com/@nurovantai/"
            className="!h-6 !w-6"
            bgColor="#98A2B3"
          />
        </aside>
      </section>
    </footer>
  );
};

export default Footer;
