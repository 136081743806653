import emma from "../assets/emma.jpg";
import sofia from "../assets/sofia.jpg";
import jordan from "../assets/jordan.jpg";
import alex from "../assets/alex.jpg";
import liam from "../assets/liam.jpg";
import aisha from "../assets/aisha.jpg";

const testimonials = [
  // {
  //   image: emma,
  //   name: "Emmanuel, Biology Major",
  //   text: "Nurovant AI has completely changed the way I study. The note-taking feature is a lifesaver during fast-paced lectures. It's like having a personal study assistant who knows exactly what I need to focus on. My grades have improved significantly since I started using it! As someone who juggles part-time work with a full course load, Nurovant AI's personalized study plans have been a game-changer. The app helps me stay organized and on track. Plus, the flashcards are perfect for quick revision sessions between classes.",
  // },
  // {
  //   image: sofia,
  //   name: "Sofia",
  //   text: "The scanning feature in Nurovant AI is amazing! Being able to scan complex problems and get step-by-step solutions has helped me understand concepts that were challenging before. It's an invaluable tool for any STEM student. I love how Nurovant AI adapts to my learning style. The quizzes are tailored to what I've studied, and they really help reinforce the material. It's not just an app; it's a comprehensive learning system.",
  // },
  // {
  //   image: aisha,
  //   name: "Aisha",
  //   text: "Nurovant AI isn't just for STEM majors – it's been a fantastic tool for organizing my notes and thoughts for my literature classes. The summary feature helps me distill key themes and ideas from the texts. It's like having a digital study group!",
  // },
  {
    image: alex,
    name: "Alex, Computer Science Student",
    text: "As someone who juggles part-time work with a full course load, Nurovant AI's personalized study plans have been a game-changer. The app helps me stay organized and on track. Plus, the flashcards are perfect for quick revision sessions between classes.",
  },
  {
    image: jordan,
    name: "Jordan",
    text: "I used to get overwhelmed with the amount of reading in my major, but Nurovant AI has made it so much more manageable. The ability to upload PDFs and turn dense texts into key points and study aids is incredible. It's like having a personal tutor.",
  },
  {
    image: liam,
    name: "Liam",
    text: "love how Nurovant AI adapts to my learning style. The quizzes are tailored to what I've studied, and they really help reinforce the material. It's not just an app; it's a comprehensive learning system.",
  },
];

export default testimonials;
