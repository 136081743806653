import React from "react";

import check from "../../assets/check.svg";
import students from "../../assets/home/students.png";
import collaborate1Mobile from "../../assets/home/collaborate1-mobile.png";
import collaborate2Mobile from "../../assets/home/collaborate2-mobile.png";
import collaborate3Mobile from "../../assets/home/collaborate3-mobile.png";
import infoImage from "../../assets/home/infoImage.png";
import infoImageM from "../../assets/home/infoImageM.png";

import teachers from "../../assets/home/teachers.png";
import institutions from "../../assets/home/institutions.png";
import ourApis from "../../assets/home/ourApis.png";
import { ChevronRight } from "lucide-react";
import DownloadApps from "../../components/DownloadApps";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";

type Props = {};

const Information = (props: Props) => {
  const navigate = useNavigate();
  return (
    <main>
      <section className="flex flex-col lg:gap-[56px]">
        <article className="mx-auto max-w-[375px] text-center lg:max-w-[765px]">
          <p className="text-sm font-semibold text-primary">
            Welcome to Nurovant
          </p>
          <h1 className="text-[26px] font-semibold">
            Your ultimate Learning Companion
          </h1>
          <p>
            Empower your learning experience with Intelligent tools and
            personalized support.
          </p>
        </article>
      </section>
      <section className="mx-auto my-14 flex w-fit flex-col gap-6 px-4 lg:flex-row">
        <aside className="bg-[#DBE2FE] px-[24px] pb-12 pt-6">
          <img src="/student-icon.svg" alt="" className="h-12 w-12" />
          <article className="mt-16">
            <h1 className="text-[20px] font-semibold text-primary">Students</h1>
            <p>Empower Yourself with Intelligent Tools for Academic Success.</p>
          </article>
          <button
            onClick={() => navigate("/students")}
            className="flex items-center gap-2 text-primary"
          >
            Learn more <ChevronRight />
          </button>
        </aside>
        <aside className="bg-[#DBE2FE] px-[24px] pb-12 pt-6">
          <img src="/teachers-icon.svg" alt="" className="h-12 w-12" />
          <article className="mt-16">
            <h1 className="text-[20px] font-semibold text-primary">Teachers</h1>
            <p>
              Empower Your Teaching, Enhance Student Learning with Nurovant AI
            </p>
          </article>
          <Link to="/teachers" className="flex items-center gap-2 text-primary">
            Learn more <ChevronRight />
          </Link>
        </aside>
        <aside className="bg-[#DBE2FE] px-[24px] pb-12 pt-6">
          <img src="/institutions-icon.svg" alt="" className="h-12 w-12" />
          <article className="mt-16">
            <h1 className="text-[20px] font-semibold text-primary">
              Institution
            </h1>
            <p>
              Elevating Education, Empowering Educators, and Enriching Student
              Experiences.
            </p>
          </article>
          <Link
            to="/institutions"
            className="flex items-center gap-2 text-primary"
          >
            Learn more <ChevronRight />
          </Link>
        </aside>
      </section>
      <section className="bg-[#1B1B1B] px-4 py-20 text-center text-white">
        {/* <h1 className="mx-auto max-w-[975px] pt-[80px] text-center text-[29px] font-bold lg:text-[44px]">
          Check our easy to use speech to quiz API
        </h1> */}
        {/* <p className="mx-auto max-w-[700px] text-center text-[24px]">
          Our suite of plug-and-play APIs and SDKs are easy to set up and
          effortless to use.
        </p> */}
        <article className=" flex flex-col-reverse items-center gap-[44px] lg:flex-row lg:justify-center ">
          <div className="max-w-[658px] space-y-4">
            <h2 className="text-[28px] font-semibold">Speech to Quiz API</h2>
            <p className="text-[20px] ">
              No matter the programming language or framework, our APIs are here
              to serve your business. PHP(Laravel), Go, Javascript(React),
              Flutter and more
            </p>

            <div className="mx-auto flex w-fit items-center gap-2 text-[20px] font-bold text-primary">
              <Link
                target="__blank"
                to="https://nurovant-ai.gitbook.io/speech-to-quiz-api/"
              >
                Read our Documentation
              </Link>
              <ChevronRight />
            </div>
          </div>
          {/* <div className="h-auto max-w-full sm:h-[390px] sm:max-w-[560px]">
            <img src={ourApis} alt="" />
          </div> */}
        </article>
      </section>
    </main>
  );
};

export default Information;
