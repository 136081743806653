import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";

export default function CustomRoutes() {
  return (
    <Routes>
      {routes.map(({ path, component }) => {
        return <Route key={path} path={path} element={component} />;
      })}
    </Routes>
  );
}

export * from "./routes";
