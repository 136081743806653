import React from "react";
import image5 from "../../assets/home/image 5.svg";
import image6 from "../../assets/home/image 6.svg";
import image7 from "../../assets/home/image 7.svg";
import image8 from "../../assets/home/image 8.svg";
import image9 from "../../assets/home/image 9.svg";
import Slider from "react-infinite-logo-slider";

type Props = {};

const LogoSlider = (props: Props) => {
  const images = [image5, image6, image7, image8, image9];
  return (
    <figure className="mx-auto mb-16 mt-6 max-w-[1216px] bg-[#F9FAFB] py-16">
      <Slider
        width={"250px"}
        className="mx-auto mt-10 flex !w-[250px] gap-16"
        blurBorders={true}
      >
        {images.map((image, index) => (
          <Slider.Slide>
            <img
              key={index}
              src={image}
              alt={`Logo ${index + 1}`}
              className="h-auto max-w-[82px] object-contain lg:max-w-[162px]"
            />
          </Slider.Slide>
        ))}
      </Slider>
    </figure>
  );
};

export default LogoSlider;
